import React from 'react';
import { useParams } from 'react-router';
import { PlanFilterDataProvider } from './PlanFilterDataContext';
import * as _ from 'lodash';
import * as QueryString from 'query-string';
import useFiltersUpdating from '../../../../hooks/useFiltersUpdating';
import {
  GetPlansYear,
  getQuoteId,
  handleCollectionChange,
  handleRatingChange,
  setQuoteId, useDebouncedEffect,
  useUrlParams
} from '@coverright/utils';
import { ExtraBenefit, PlansFilterInput, PlanYear, Rating, SnpType } from '@coverright/data-access/types/medicare';
import { useMedicareQuote } from '@coverright/data-access/medicare';
import { QuoteContext } from '@coverright/shared/contexts';

export interface IPlansFilter extends PlansFilterInput {
  companies: string[];
  isMA?: boolean;
  isMD?: boolean;
  isMS?: boolean;
  partBPremiumReduction?: boolean;
  planTypes: string[];
  SNPTypes: SnpType[];
  extraBenefits: ExtraBenefit[];
  rating: Rating[];
  zip: string;
  countyName: string;
  planYear?: PlanYear;
}

interface PlanFilterContextState {
  values?: IPlansFilter,
  debouncedValues?: IPlansFilter,
  switchZip: (zip: string, countyName: string) => Promise<any>,
  toggleFavorites: () => void,
  switchCompany: (name: string) => void,
  switchPlanType: (name: string) => void,
  switchYear: (year: PlanYear) => void,
  switchSNPType: (type: SnpType) => void,
  switchRating: (rating: Rating) => void,
  switchExtraBenefits: (type: ExtraBenefit) => void,
  switchMaxOutOfPocketRanges: (name: string) => void,
  setFilterValues: (values: {value: any, filterKey: keyof IPlansFilter}[]) => void,
  switchMaxCostRanges: (name: string) => void,
  togglePartB: () => void,
  refresh: () => void,
  reset: () => void,
}

const defaultState: PlanFilterContextState = {
  values: {
    zip: "NOT_DEFINED",
    countyName: "NOT_DEFINED",
    isMA: true,
    partBPremiumReduction: false,
    planYear: GetPlansYear(),
    onlyFavorite: false,
    companies: [],
    planTypes: [],
    rating: [],
    SNPTypes: [],
    extraBenefits: [],
    maxCostRangeNames: [],
    maxOutOfPocketRangeNames: []
  },
  setFilterValues: (values: {value: any, filterKey: keyof IPlansFilter}[]) => {},
  switchZip: (zip: string) => (zip as any),
  toggleFavorites: () => {},
  togglePartB: () => {},
  switchCompany: (name: string) => {},
  switchPlanType: (name: string) => {},
  switchSNPType: (type: SnpType) => {},
  switchYear: (year: PlanYear) => {},
  switchRating: (rating: Rating) => {},
  switchExtraBenefits: (type: ExtraBenefit) => {},
  switchMaxOutOfPocketRanges: (name: string) => {},
  switchMaxCostRanges: (name: string) => {},
  refresh: () => {},
  reset: () => {},
};

export const PlanFilterContext = React.createContext<PlanFilterContextState>(defaultState);

export function PlanFilterProvider(props: React.PropsWithChildren<PlanFilterProviderProps>) {

  const quote = React.useContext(QuoteContext);
  const params = useUrlParams(['resetFilters', 'zip', 'county', 'planType', 'maxOutOfPocketRangeNames', 'adminQuote', 'planYear']);
  const [filters, setFilters] = React.useState<IPlansFilter>();
  const [debouncedValues, setDebouncedValues] = React.useState<IPlansFilter>();
  const [prevQuoteId, setPrevQuoteId] = React.useState();
  const {setFiltersUpdating} = useFiltersUpdating();
  const [adminQuote, setAdminQuote] = React.useState<any>({});

  const [getAdminQuote, adminQuoteData] = useMedicareQuote({
    onCompleted: data => {
      const quote = data.medicareQuote;
      if (quote) {
        setFilters({
          zip: quote.zip,
          countyName: quote.county as string,
          ...quote.filters as any
        })
        setAdminQuote({
          preferredDoctors: quote.preferredDoctors.map((d: any) => ({
            npi: d.npi,
            addressId: d.address.id
          })),
          preferredPharmacies: quote.preferredPharmacies?.map((p: any) => p.npi),
          drugDeliveryType: quote.drugDeliveryType,
          doctorVisitsNumber: quote.doctorVisitsNumber,
          specialistVisitsNumber: quote.specialistVisitsNumber,
          favorites: quote.favorites,
        })
      }
    }
  });

  React.useEffect(() => {
    if (filters) {
      sessionStorage.setItem('filters', JSON.stringify(filters))
    }
  }, [filters])

  useDebouncedEffect(() => {
    setDebouncedValues(filters)
  }, 700, [filters])

  React.useEffect(() => {
      if (params.resetFilters) {
        sessionStorage.removeItem('filters');
      } else {
        if (params.zip && params.county) {
          const storedFilters = sessionStorage.getItem('filters');
          let data: any = props.initFilter;
          if (storedFilters) {
            data = JSON.parse(storedFilters);
          }
          data.zip = params.zip;
          data.countyName = params.county;
          if (params.planType) {
            data.planTypes = [params.planType]
          }
          if (params.maxOutOfPocketRangeNames) {
            data.maxOutOfPocketRangeNames = [params.maxOutOfPocketRangeNames]
          }
          sessionStorage.setItem('filters', JSON.stringify(data));
        }
      }
      if (params.adminQuote) {
        getAdminQuote({variables: {id: params.adminQuote as string}})
      }
  }, [params]);

  React.useEffect(() => {
    let planYear: PlanYear = quote.planYear || GetPlansYear();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('planYear')) {
      planYear = urlParams.get('planYear') as PlanYear;
    }
    if (quote.pin && prevQuoteId !== quote.id) {
      const result: any = {...props.initFilter, ...quote.filters, planYear };
      if (!_.isEqual(filters, result)) {
        setFilters(result)
      }
      setPrevQuoteId(quote.id);
    } else if (!quote.id) {
      setFilters(() => {
        const storedFilters = sessionStorage.getItem('filters');
        if (storedFilters) {
          const parsed = JSON.parse(storedFilters);
          if (parsed.zip !== props.initFilter.zip) {
            parsed.zip = props.initFilter.zip
          }
          if (parsed.countyName !== props.initFilter.countyName) {
            parsed.countyName = props.initFilter.countyName
          }
          parsed.planYear = planYear;
          return parsed;
        } else {
          return props.initFilter
        }
      })
    }
  }, [quote])

  function setFilterValues(values: {value: any, filterKey: keyof IPlansFilter}[]) {
    setFilters(prev => {
      if (prev) {
        const newInstance = {...prev}
        values.forEach(({value, filterKey}) => {
          newInstance[filterKey] = value;
        })
        setFiltersUpdating(true);
        saveQuote(newInstance).then(() => setFiltersUpdating(false));
        return newInstance
      }
      return prev;
    })
  }

  function switchFilter<T>(key: T, filterKey: keyof IPlansFilter) {
    setFilters(prev => {
      if (prev) {
        const newInstance = {...prev}
        if (filterKey === 'rating') {
          newInstance[filterKey] = handleRatingChange(key as unknown as Rating, prev[filterKey])
        } else {
          newInstance[filterKey] = handleCollectionChange<T>(key, prev[filterKey])
        }
        setFiltersUpdating(true);
        saveQuote(newInstance).then(() => setFiltersUpdating(false));
        return newInstance
      }
      return prev;
    })
  }

  /*useDebouncedEffect(() => {
    if (filters && !isInitFilters(filters, props.initFilter)) {
      saveQuote(filters);
    } else {
      setFiltersUpdating(false);
    }
  }, 2000, [filters])*/

  const saveQuote = React.useCallback((filters: IPlansFilter) => {
    const {save} = quote;
    return save({variables: {
        data: {
          id: getQuoteId(),
          county: filters.countyName,
          zip: filters.zip,
          planYear: filters.planYear,
          filters: {
            extraBenefits: filters?.extraBenefits as any,
            SNPTypes: filters?.SNPTypes,
            companies: filters?.companies,
            maxCostRangeNames: filters?.maxCostRangeNames,
            maxOutOfPocketRangeNames: filters?.maxOutOfPocketRangeNames,
            partBPremiumReduction: filters?.partBPremiumReduction,
            planTypes: filters?.planTypes,
            rating: filters?.rating,
          },
          preferredDoctors: undefined,
        }
      }}).then((data: any) => {
      if (!quote?.id) {
        setQuoteId(data.data.saveMedicareQuote.maQuoteID)
      }
      setFiltersUpdating(false);
    })
  }, [quote]);

  function refresh() {
    setFilters(prev => {
      return prev ? {...prev} : prev;
    })
  }

  const contextValue = React.useMemo<PlanFilterContextState>(() => (
      {
        values: filters,
        debouncedValues,
        setFilterValues,
        reset: () => {
          const initial = {...props.initFilter, planYear: filters?.planYear};
          setFilters(initial);
          return saveQuote(initial as any);
        },
        switchZip: (zip: string, countyName: string) => {
          const updated = {...filters, zip, countyName};
          setFilters({...props.initFilter, zip, countyName})
          return saveQuote(updated as any);
        },
        toggleFavorites: () => {
          setFilters(prev => {
            if (prev) {
              const newInstance = {...prev, onlyFavorite: !prev.onlyFavorite}
              saveQuote(newInstance);
              return newInstance
            }
            return prev;
          })
        },
        togglePartB: () => {
          setFilters(prev => {
            if (prev) {
              const newInstance = {...prev, partBPremiumReduction: !prev.partBPremiumReduction}
              saveQuote(newInstance);
              return newInstance
            }
            return prev;
          })
        },
        switchYear: (planYear: PlanYear) => {
          setFilters(prev => {
            if (prev) {
              const newInstance = {...prev, planYear}
              saveQuote(newInstance);
              return newInstance
            }
            return prev;
          })
        },
        switchCompany: (key: string) => {
          switchFilter<string>(key,'companies')
        },
        switchPlanType: (key: string) => {
          switchFilter<string>(key,'planTypes')
        },
        switchRating: (key: Rating) => {
          switchFilter<Rating>(key,'rating')
        },
        switchSNPType: (key: SnpType) => {
          switchFilter<SnpType>(key,'SNPTypes')
        },
        switchExtraBenefits: (key: ExtraBenefit) => {
          switchFilter<ExtraBenefit>(key,'extraBenefits')
        },
        switchMaxOutOfPocketRanges: (key: string) => {
          switchFilter<string>(key,'maxOutOfPocketRangeNames')
        },
        switchMaxCostRanges: (key: string) => {
          switchFilter<string>(key,'maxCostRangeNames')
        },
        refresh
      }
    )
  , [filters]);

  return <PlanFilterContext.Provider value={contextValue}>
    {props.children}
  </PlanFilterContext.Provider>

}

function isInitFilters(filters: any, initFilters: any) {
  const isEqual = (keys: string[]) => {
    return keys.map(k => _.isEqual(filters[k], initFilters[k])).every(Boolean);
  }
  return isEqual(['extraBenefits', 'SNPTypes', 'companies', 'maxCostRangeNames', 'maxOutOfPocketRangeNames', 'partBPremiumReduction', 'planTypes', 'rating', 'zip', 'countyName'])
}

export const withPlanFilterProvider = (WrappedComponent: any) => (props: any) => {
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  const params = QueryString.parse(document.location.search);
  const planTypes: any[] = [];
  const SNPTypes: any[] = [];
  let planYear = GetPlansYear().toString();

  if (params.planType) {
    planTypes.push(params.planType)
  }

  if (params.SNPType) {
    SNPTypes.push(params.SNPType)
  }

  if (params.planYear) {
    planYear = params.planYear as string;
  }

  return (
    <PlanFilterDataProvider>
      <PlanFilterProvider initFilter={{
        zip: zip!,
        countyName: countyName!,
        maxOutOfPocketRangeNames: [],
        maxCostRangeNames: [],
        companies: [],
        planTypes,
        rating: [],
        SNPTypes,
        extraBenefits: [ExtraBenefit.DrugCoverage],
        planYear: planYear as PlanYear
      }}>
        <WrappedComponent {...{...props}} />
      </PlanFilterProvider>
    </PlanFilterDataProvider>
  )
}

interface PlanFilterProviderProps {
  initFilter: IPlansFilter
}
/***
 switchMaxOutOfPocketRanges: (name: string) => {},
 switchMaxCostRanges: (name: string) => {},


 maxCostRangeNames: [],
 maxOutOfPocketRangeNames: []
 */
