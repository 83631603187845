import React, { useContext, useEffect } from 'react';
import './app.module.scss';
import { ApolloProvider } from '@apollo/client';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  getClientId,
  getQuoteId,
  resetStoredUser
} from '@coverright/utils';
import { ComparisonProvider } from './ComparisonContext';
import paths from '../config/router-paths';
import DashboardLayout from './pages/plan-list/DashboardLayout';
import { ThemeProvider } from '@mui/material';
import { AppTheme } from '@coverright/ui/themes';
import { LayoutWithNotification } from '@coverright/ui/marketplaces';
import EditPreferences from './pages/edit-preferences/EditPreferences';
import DetailedPlan from './pages/detailed-plan/detailed-plan';
import PlanComparison from './pages/plan-comparison/plan-comparison';
import Favorites from './pages/favorites/favorites';
import {
  keycloak,
  withKeycloakContext
} from '@coverright/shared/keycloak';
import {
  AdminContextProvider, CompareOfferContextProvider,
  FavoritesProvider, QuoteContext,
  QuoteProvider, withAppContextProvider
} from '@coverright/shared/contexts';
import { client, setErrorHandler } from '@coverright/data-access/apollo-clients';
import AdminNotification from './components/AdminNotification';
import * as _ from 'lodash';
import { useNavigate } from 'react-router';
import { CRPreloader } from '@coverright/ui/shared';

function App() {
  const { enqueueSnackbar } = useSnackbar();

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
        switch (extensions?.status) {
          case '403':
            {
              if (keycloak?.authenticated) {
                resetStoredUser();
                document.location.href = document.location.origin;
              } else {
                keycloak?.login();
              }
            }
            break;
          default: {
            console.error(
              `Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            );
            enqueueSnackbar('Network error, please try again later', {
              variant: 'error',
            });
          }
        }
      });
  });



  return (
    <ThemeProvider theme={AppTheme}>
      <ComparisonProvider>
        <ApolloProvider client={client}>
          <AdminContextProvider clientId={getClientId()}>
            <QuoteProvider quoteId={getQuoteId()}>
              <FavoritesProvider>
                <CompareOfferContextProvider adminOnly>
                  <LayoutWithNotification showNotification NotificationComponent={<AdminNotification />}>
                    <Routes>
                      <Route
                        path={paths.plans + ':zip/:countyName'}
                        element={<DashboardLayout />}
                      />
                      <Route
                        path={paths.plans}
                        element={<Redirect />}
                      />
                      <Route
                        path={paths.editPreferences + ':zip/:countyName/:tab?'}
                        element={<EditPreferences />}
                      />
                      <Route
                        path={paths.planDetails + ':zip/:countyName/:planId'}
                        element={<DetailedPlan />}
                      />
                      <Route
                        path={paths.comparison + ':zip/:countyName'}
                        element={<PlanComparison />}
                      />
                      <Route
                        path={paths.favorites + ':zip/:countyName'}
                        element={<Favorites />}
                      />
                      <Route path={'*'} element={<Navigate to={paths.plans + document.location.search} />} />
                    </Routes>
                  </LayoutWithNotification>
                </CompareOfferContextProvider>
              </FavoritesProvider>
            </QuoteProvider>
          </AdminContextProvider>
        </ApolloProvider>
      </ComparisonProvider>
    </ThemeProvider>
  );
}

export default _.flowRight([withKeycloakContext, withAppContextProvider])(App)

const Redirect = () => {
  const navigate = useNavigate();
  const {zip, county} = useContext(QuoteContext);

  useEffect(() => {
    if (zip && county) {
      navigate(`${paths.plans}${zip}/${county}`)
    }
  }, [zip, county, navigate]);

  return <CRPreloader />
}
