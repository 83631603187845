import React from 'react';
import { useSnackbar } from 'notistack';
import PlanListSort from './components/PlanListSort';
import { useParams } from 'react-router';
import * as QueryString from 'query-string';
import { Box, Button, FormControlLabel, Switch, Typography,  } from '@mui/material';
import { CRPreloader, withErrorBoundary } from '@coverright/ui/shared';
import { ComparisonContext } from '../../ComparisonContext';
import { PlanFilterContext } from './components/filters/PlanFilterContext';
import {
  areComponentPropsEqual,
  GetNextPlansYear,
  GetPlansYear,
  getQuoteId,
  useDebouncedEffect
} from '@coverright/utils';
import PlanCard from './components/PlanCard';
import FiltersBadges from './components/filters/FiltersBadges';
import Messages from './components/Messages';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { useMedicarePlans } from '@coverright/data-access/medicare';
import { UserTestContext, AppContext, FavoritesContext, QuoteContext } from '@coverright/shared/contexts';
import { DrugOutput, Field, Plan, PlanYear, Sort } from '@coverright/data-access/types/medicare';
import { Footer } from '@coverright/ui/marketplaces';


function PlanList({onPlansCountChange}: {onPlansCountChange: (count: number) => void}) {
  const pageSize = 4;
  const {zip, countyName: county} = useParams<{zip: string, countyName: string}>();

  const [sortBy, setSortBy] = React.useState<Field>(Field.MonthlyCost);
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  //todo showAllPlans
  const {id, preferredDoctors, preferredDrugs, save} = React.useContext(QuoteContext);
  const {config} = React.useContext(UserTestContext);
  const {authenticated, isAdmin} = React.useContext(KeycloakContext);
  const {clear: clearComparison} = React.useContext(ComparisonContext);
  const { enqueueSnackbar } = useSnackbar();
  const [{state: {plansShown}}, dispatch] = React.useContext(AppContext);

  const [total, setTotal] = React.useState<number>();
  const [request, results] = useMedicarePlans()

  React.useEffect(() => {
    window.scrollTo(0, 0)

    const parsedQuery = QueryString.parse(document.location.search);

    if (parsedQuery.skipCreateAccount && parsedQuery.quote && !localStorage.getItem('praiseCardShown')) {
      localStorage.setItem('praiseCardShown', 'true');
    } else {
      showTour();
    }

    if (parsedQuery.skipCreateAccount) {
      localStorage.setItem('createAccountShown', 'true');
    }

    if (id && !authenticated && !localStorage.getItem('createAccountShown') && localStorage.getItem('showCreateAccountModal')) {
      setTimeout(() => {
        localStorage.setItem('createAccountShown', 'true');
        localStorage.removeItem('showCreateAccountModal');
        // setCreateAccountModalOpen(true)
      }, 7000)
    }

    localStorage.setItem('plansShown', 'true')
  }, []);

  const showTour = () => {
    if (!localStorage.getItem('tourShown') && !authenticated  && window.innerWidth > 1280) {
      localStorage.setItem('tourShown', 'true');
      // setIsTourOpen(true)
    }
  }

  const {values: filterValues, debouncedValues, switchZip, switchYear} = React.useContext(PlanFilterContext)

  const loadMore = React.useCallback(() => {
    setPage({
      ...page,
      page: page.page + 1
    })
  },[page]);

  useDebouncedEffect(() => {
    if (zip) {
      setPlans([]);
      setPage({
        page: 0,
        size: pageSize
      });
    //  window.scrollTo(0, 0)
    }
  }, 700, [filterValues, sortBy]);

  const quoteId = React.useMemo(() => {
    return id || getQuoteId();
  }, [id])

  React.useEffect(() => {
    if ((hasMorePlans || !plans.length) && filterValues) {
      let sort;
      switch (sortBy) {
        case Field.Rating: sort = [
          { "field": Field.Rating,"direction":Sort.Desc },
          { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
          { "field": Field.Deductible, "direction": Sort.Asc},
          { "field": Field.Name, "direction": Sort.Asc}
        ]; break;
        case Field.DoctorsCoverage: sort = [
          { "field": Field.DoctorsCoverage,"direction":Sort.Desc },
          { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
          { "field": Field.Deductible, "direction": Sort.Asc},
          { "field": Field.Name, "direction": Sort.Asc}
        ]; break;
        default: sort = [
          { "field": Field.MonthlyCost,"direction":Sort.Asc },
          { "field": Field.Premium,"direction":Sort.Asc },
          { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
          { "field": Field.Deductible, "direction": Sort.Asc},
          { "field": Field.Name, "direction": Sort.Asc}
        ];
      }

      request({
        variables: {
          filter: {...filterValues, showAllPlans: false},
          fullYear: filterValues.planYear === GetNextPlansYear(),
          page,
          quoteId: quoteId || null,
          sort
        }
      })
    }
  }, [page]);

  const hasMorePlans = React.useMemo(() => {
    return !total || plans.length < total;
  }, [total, plans.length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data.plans.totalElements)
      setPlans([...plans, ...results.data.plans.data])
    }
  }, [results.data]);

  React.useEffect(() => {
    onPlansCountChange(total || 0)
  }, [total]);

  React.useEffect( () => {
    if(results.error) {
      enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'}); // todo add text
    }
  }, [results.error]);

  return <>
    <Box  data-test={'ma_plan_list'} flexDirection={'column'} position={'relative'} maxWidth={'800px'} alignItems={'center'}>
      <Messages total={total} plans={plans} loading={results.loading || !results.called}
                preferredDoctors={preferredDoctors}
                preferredDrugs={preferredDrugs}
                rating={debouncedValues?.rating}
                extraBenefits={debouncedValues?.extraBenefits}
                SNPTypes={debouncedValues?.SNPTypes}
                planTypes={debouncedValues?.planTypes}
                zip={zip}
                countyName={county}
      />
      <Box>
        <PlanListSort onChange={setSortBy} favoritesCount={favorites.length} value={sortBy} zip={zip} county={county} />
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <FiltersBadges />
          {(!!config.compare_year_to_year || isAdmin) && <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Typography className={'mr-12'}>2023</Typography>
            <FormControlLabel control={<Switch checked={filterValues?.planYear === PlanYear.Year2024}
                                               onChange={e => switchYear(e.target.checked ? PlanYear.Year2024 : PlanYear.Year2023)}  />}
                              label={'2024'} />
          </Box>}
        </Box>

        <MemoizedList plans={plans}
                      plansLoaded={!results.loading && results.called}
                      show={!!filterValues}
                      favorites={favorites}
                      planYear={filterValues?.planYear || GetPlansYear()}
                      toggleFavorite={toggleFavorite}
                      authenticated={authenticated}
                      preferredDrugs={preferredDrugs}
                      zip={zip!}
                      county={county!} />
        {results.loading &&
          <Box sx={{
            width: '100%',
            position: 'absolute',
            top: 0,
            zIndex: 1,
            background: '#f5f7f6',
            minHeight: '100vh',
            height: '100%'
          }}>
            <Box sx={{ position: 'sticky', top: 'calc(50vh - 20px)' }}>
              <CRPreloader sx={{ margin: 'auto', width: '100px' }}/>
            </Box>
          </Box>
        }
        <LoadMorePlansButton onClick={loadMore} disabled={results.loading} show={hasMorePlans && !results.loading && plans.length > 0} />
      </Box>
      <Footer zip={zip} />
    </Box>
  </>
}

export default withErrorBoundary(PlanList);

interface MemoizedListProps {
  plans: Plan[],
  plansLoaded: boolean,
  show: boolean,
  zip: string,
  county: string,
  planYear: PlanYear,
  preferredDrugs: DrugOutput[],
  authenticated?: boolean,
  favorites: string[],
  toggleFavorite: (bidId: string, zip: string) => void,
}

const MemoizedList = React.memo(({plans, plansLoaded, show, zip, county, planYear, authenticated, preferredDrugs, favorites, toggleFavorite}: MemoizedListProps) => {
  return <>
    {show && <>
      {!!plans.length && plans.map(plan => <PlanCard plan={plan}
                                                     authenticated={authenticated}
                                                     favorites={favorites}
                                                     toggleFavorite={toggleFavorite}
                                                     zip={zip}
                                                     planYear={planYear}
                                                     countyName={county}
                                                     preferredDrugs={preferredDrugs}
                                                     key={plan.bidId} />)}
      {(!plans.length && plansLoaded) && <Typography color={'textPrimary'}
                                                     align={'center'}
                                                     className={'fs-18 bold mt-50 mb-110'}>
        There are no plans available based on your search criteria. Try adjusting your filter settings to see more plans.
      </Typography>}
    </>}
  </>
}, areComponentPropsEqual)

const LoadMorePlansButton = React.memo(({show, onClick, disabled}: {show: boolean, disabled: boolean, onClick: any}) => <>
  {show && <Box textAlign={"center"} mt={'32px'}>
    <Button sx={{minWidth: 250}} variant={'outlined'} disabled={disabled} onClick={onClick}>Load More Plans</Button>
  </Box>}
</>)

